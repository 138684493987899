<template>
  <div>
    <div class="viewEdit clock-in-out-table">
      <div class="card-header-edit align-items-center">
        <div class="projects-list">

          <b-avatar
            variant="primary"
            size="50"
            :text="userInfoData ? userInfoData.full_name :''| avatarText"
            :src="userInfoData && userInfoData.picture ? userInfoData.picture : '' "
            :style="{ 'background-color': '#11111' }"
            class="avtar"
          />
          {{ userInfoData ? userInfoData.full_name: null }}
        </div>
        <div class="calendar mt-1 mt-xl-0">
          <div
            id="date-select"
            class="date-select mr-0 "
          >
            <custom-calender
              :start="startDate"
              :end="startDate"
              :date-range-config="dateDayRangeConfig"
              :from="'clock-in-out'"
              @date="updateDate"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between mb-2 back-time-sec">
      <b-button
        class="back-button"
        @click="Back()"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          width="18"
          height="18"
        />
        <span>Back </span>
      </b-button>
      <span
        style="font-weight: bold"
        class="total-hr-timespent mb-0"
      >Total Time : <span>{{ totalMinute }}</span>
      </span>
    </div>

    <div style="float: left; width: 100%">
      <table-loader
        v-if="loader"
        :fields="fields"
      />
    </div>
    <clock
      v-if="!loader"
      :data-log="data"
      :user-id="user_id"
    />

  </div>
</template>

<script>
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import { BButton } from 'bootstrap-vue'
import clock from '../../@core/components/clockInOut/inOutLog.vue'

export default {
  components: {
    'b-button': BButton,
    clock,
  },
  data() {
    return {
      loader: false,
      startDate: null,
      endDate: null,
      users: [],
      data: [],
      userInfoData: null,
      user_id: null,
      dateDayRangeConfig: {
        inline: true,
        dateFormat: 'Y-m-d',
        mode: 'single',
        maxDate: new Date(),
      },
      fields: [
        {
          key: 'in_time',
          label: 'In',
          sortable: false,
          thStyle: { width: '200px' },
          tdStyle: { width: '200px' },
        },
        {
          key: 'out_time',
          label: 'Out',
          sortable: false,
          thStyle: { width: '200px' },
          tdStyle: { width: '200px' },
        },
        {
          key: 'time_diff',
          label: 'Time',
          sortable: false,
          thStyle: { width: '97px' },
          tdStyle: { width: '97px' },
        },
      ],
    }
  },
  computed: {
    totalMinute() {
      return this.totalClockInOutMinute(this.data)
    },
  },
  watch: {
    user_id() {
      this.clockInOut()
    },
  },

  mounted() {
    this.startDate = this.$route.query.date ? this.$route.query.date : moment().format('YYYY-MM-DD')
    this.endDate = this.$route.query.date ? this.$route.query.date : moment().format('YYYY-MM-DD')
    this.user_id = this.$route.query.user_id
    this.getUserData()

    eventBus.$on('loadInOutList', data => {
      if (data) {
        this.clockInOut()
      }
    })
    eventBus.$on('LogUpdate', data => {
      if (data) {
        this.clockInOut()
      }
    })
    eventBus.$on('logListReLoad', data => {
      if (data) {
        this.clockInOut()
      }
    })
  },
  methods: {
    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.endDate = this.startDate
      this.clockInOut()
    },
    /* user List */
    async clockInOut() {
      this.loader = true
      if (!this.startDate) {
        return
      }
      const input = {
        user_id: this.user_id,
        date: this.startDate,
      }

      const response = await this.getHTTPPostResponse(
        'clock-in-out/list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.data = data
        this.loader = false
      }
    },

    async getUserData() {
      const response = await this.getHTTPGetResponse(
        `admin/master/user/view/${this.user_id}`,
        false,
      )
      if (response && response.data) {
        this.userInfoData = response.data.user
        this.loader = false
      }
    },

    Back() {
      this.$router.push({
        name: 'hrTimeSheet',
        query: {
          is_active: this.$route.query.is_active,
          manage_by: this.$route.query.manage_by,
          reporting_to: this.$route.query.reporting_to,
          userID: this.$route.query.userID,
          group_id: this.$route.query.group_id,
          startDate: this.$route.query.startDate,
          endDate: this.$route.query.endDate,
          pageNumber: this.$route.query.pageNumber,
          perPage: this.$route.query.perPage,
          active: this.$route.query.active,
          tab: 'weekly',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/approvals.css";
@import "../../assets/scss/component-css/report.css";
@import "../../assets/scss/component-css/vieweditheader.css";
.hr-weekly-view {
  left: -70px !important;
}
.back-button {
  background-color: #3479fb !important;
  padding: 5px;
  color: #ffffff;

  display: flex;
  align-items: center;

  &:hover {
    background-color: #3479fb !important;
    color: #ffffff;
  }
}
.back-time-sec {
  background: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}
.date-picker-general {
  background-color: rgba(33, 120, 251, 0.07) !important;
  padding: 9px 22px 9px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  position: relative;
  width: 320px; //   @media (max-width: map-get($mediaSizes , "md")) {

  .dropdown-toggle {
    color: #333333 !important;
    size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .side-bar-fillter-calendar {
    border-color: #f97753 !important;
    margin-right: 10px;
    padding: 9px 25px;
    color: #ffffff !important;
    font-size: 14px;
    font-weight: 600;
    background-color: #f97753 !important;
    &:focus {
      background-color: #f97753 !important;
      color: #ffffff !important;
    }
  }
  .side-bar-clear-calendar {
    background-color: transparent !important;
    padding: 9px 25px;
    color: #f97753 !important;
    border: 1px solid #f97753 !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
  }
}

.btn-secondary {
  background-color: aqua;
}
.total-hr-timespent {
  color: #5e5873;
  font-weight: 600;
  font-size: 20px;
  text-align: end;

  margin-right: 5px;

  span {
    color: #ff3700;
  }
}
.select-user-clock {
  width: 300px;
  .vs__selected {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 280px;
    text-transform: capitalize;
    color: #333333;
  }
  .vs__search {
    padding: 3px 7px;
    &::placeholder {
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .vs__dropdown-toggle {
    background: #ffffff;
    border: 1px solid #dddbe2;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #333333 !important;
    opacity: 1;
  }
  .vs__selected-options {
    padding: 3px 6px;
  }
  .vs__open-indicator {
    fill: #333333;
  }
  .vs__clear {
    fill: #333333;
  }

}

</style>
